
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Grid } from '@mui/material'
import { Modal } from 'components/Modal/Modal'
import { createToast } from 'reduxSlice/toastSlice'
import { headersScada } from 'services/headers'
import { urls } from 'utils/constant'

export const ModalConfigPotMaxEms = ({ configEms, master, open, setOpen, potMax, potMaxEnable, dataEdit, meter, setConfigEms, setDevices }) => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const token = sessionStorage?.token

    const functionSave = () => {
        fetchEdit()
    }

    const fetchEdit = () => {
        setLoading(true)
        let status
        let body = {
            potmax: { enable: potMaxEnable, max_power: Number(potMax) },
            battery: { enable: potMaxEnable ? false : configEms?.battery?.enable },
            self_consumption: { enable: potMaxEnable ? false : configEms?.self_consumption?.enable },
            surplus: { enable: potMaxEnable ? false : configEms?.surplus?.enable }
        }
        fetch(urls.scada + `emsConfig/${master?.numserie}?idCcMaster=${master?.numserie}`, { method: 'PATCH', headers: headersScada(token), body: JSON.stringify(body) })
            .then(res => {
                if (res.status === 200) {
                    let copyConfig = { ...configEms }
                    if (potMaxEnable) {
                        copyConfig.battery.enable = false
                        copyConfig.surplus.enable = false
                        copyConfig.self_consumption.enable = false
                    }
                    copyConfig.potmax.max_power = potMax ? potMax : configEms?.potmax?.max_power
                    copyConfig.potmax.enable = potMaxEnable
                    setConfigEms(copyConfig)
                    status = res.status
                    fetch(urls.scada + `emsDevices/${master?.numserie}?idCcMaster=${master?.numserie}`, { method: 'PATCH', headers: headersScada(token), body: JSON.stringify(dataEdit) })
                        .then(res => {
                            if (res.status === 200) {
                                status = res.status
                                setDevices(dataEdit)
                                dispatch(createToast({ status: status, message: "Configuración actualizada correctamente", reload: false }))
                            } else {
                                status = res.status
                                dispatch(createToast({ status: status, message: "Ha habido un error al actualizar la configuración", reload: false }))
                            }
                        }).catch((error) => {
                            console.error("Ha habido un error en API Scada", error)
                        }).finally(() => {
                            setLoading(false)
                            setOpen(false)
                        })
                } else {
                    setLoading(false)
                    setOpen(false)
                    status = res.status
                    dispatch(createToast({ status: status, message: "Ha habido un error al actualizar la configuración", reload: false }))
                }
            }).catch((error) => {
                setLoading(false)
                setOpen(false)
                console.error("Ha habido un erroe en API Scada", error)
            })
    }

    return (
        <Modal onClick={(e) => e.stopPropagation()} maxWidth="sm" open={open} setOpen={setOpen} disabled={loading || (!meter && potMaxEnable)} title={`Guardar configuración de ${master?.nick}`} loading={loading} functSave={functionSave} buttonAction="Aceptar" >
            <Grid item xs={12} className="text-center mt-2">
                <span>
                    <h3 style={{ fontSize: '1.2rem' }} className="font-weight-lighter">
                        ¿Estás seguro que deseas actualizar la configuración?
                    </h3>
                    {!meter && potMaxEnable &&
                        <h3 style={{ color: 'red' }} className="font-weight-lighter">
                            Para configurar la potencia máxima del EMS se requiere un punto de frontera. Por favor, seleccione uno para continuar.
                        </h3>
                    }
                </span>
            </Grid>
        </Modal>
    )
}