import React, { useState, useEffect } from "react"
import { Grid } from "@mui/material"
import { CSVLink } from "react-csv"
import moment from "moment"
import { Loader } from 'components/Loader/Loader'
import { MenuInformesConsumo } from "views/Scada/Consumption/GraphConsumption/MenuInformesConsumo.jsx"
import ConsuptionTable from "views/Scada/Consumption/GraphConsumption/ConsuptionTable.jsx"
import { DropDownDevices } from "views/Devices/MenuDevices/DropDownDevices"
import { esHoy, isMobile, compararFecha } from "utils/Utils.jsx"
import { cambiarCabecera } from "utils/funcionesCsv.jsx"
import { SelectorFecha } from "views/SelectorCalendar/SelectorFecha.jsx"
import { headersScada } from "services/headers.jsx"
import { OjoConsumo } from "utils/icon-centrocontrol.jsx"
import { urls, dispositivos } from "utils/constant.jsx"
import { ScadaVtn } from "./ScadaVtn.jsx"
import ConsuptionPowerRealTime from "views/Scada/Consumption/GraphConsumption/ScadaConsumptionPower/PhasesActivepower.jsx"
import ConsuptionIntensityRealTime from "views/Scada/Consumption/GraphConsumption/ScadaConsumptionIntensity/ConsuptionIntensityRealTime.jsx"
import ConsuptionTensionRealTime from "views/Scada/Consumption/GraphConsumption/ScadaConsumptionTension/ConsuptionTensionRealTime.jsx"
import { GraficaMabt } from "./GraficaMabt.jsx"
import { cargarDatosGrafica, convertirHistoricoEnergiaGrafica } from "./utils.jsx"
import { HtmlTooltip } from "components/Tooltip/HtmltoolTip.jsx"
import { IconDownloadFile } from "utils/icon-centrocontrol.jsx"
import CalendarComponent from "components/Calendar/CalendarComponent.jsx"

export const ScadaMabtMedidor = ({ token, device, data, devices, masterPermission, mabt, idMaster }) => {
    const [loading, setLoading] = useState(true)
    const [loadingGraph, setLoadingGraph] = useState(true)
    const [graphData, setGraphData] = useState()
    const [energyData, setEnergyData] = useState()
    const [typeGraph, setTypeGraph] = useState('pact')
    const [typeField, setTipoField] = useState('kW')
    const [graphVisible, setGraphVisible] = useState(true)
    const now = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    const [start, setStart] = useState(moment())
    const [end, setEnd] = useState(moment())
    const [groupDate, setGroupDate] = useState("day")
    const [daily, setDaily] = useState("days")
    const [today, setToday] = useState(true)
    const mobile = isMobile(1535)
    const [activeTabDevice, setActiveTabDevice] = useState(0)
    const [activeTabGraph, setActiveTabGraph] = useState(0)
    const toggleDevice = (tab) => setActiveTabDevice(tab)
    const [graphVisibility, setGraphVisibility] = useState(true)
    const [selectDaily, setSelectDaily] = useState("day")
    let url = `chart/getDataChartsCurves/${device?.numserie}?idCCMaster=${idMaster}`

    const fetchEnergy = async () => {
        setLoading(true)
        try {
            let res = null
            res = await fetch(urls.scada + `getHistoricalDataScada/${device?.numserie}?idCCMaster=${idMaster}&iniDate=${start.format("YYYY-MM-DD")}&endDate=${end?.format("YYYY-MM-DD")}&groupDate=${daily}`, { method: "GET", headers: headersScada(token) })
            if (res.status === 200) {
                const json = await res.json()
                setEnergyData(json)
                if (activeTabGraph === 2) {
                    setGraphData(convertirHistoricoEnergiaGrafica(json, typeGraph))
                }
            } else {
                return []
            }
        } catch (err) {
            console.error("ERROR. API Scada", err)
        } finally {
            setLoadingGraph(false)
            setLoading(false)
        }
    }

    const fetchDataGraph = () => {
        let auxToday = start.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
        let aux = device?.id_tipo_dispositivo === dispositivos.ccmabtm ? [typeGraph] : device?.id_tipo_dispositivo === dispositivos.ccmabtv && esHoy(start) ? ["vtn"] : ["vtn_max", "vtn_min"]
        fetch(urls.scada + url, {
            method: "POST", redirect: "follow", headers: headersScada(token),
            body: JSON.stringify({
                iniDate: `${start.format("YYYY-MM-DD")}`,
                endDate: `${end.format("YYYY-MM-DD")}`,
                iniTime: `00:00`,
                endTime: auxToday ? `${moment().format("HH:mm")}` : `23:59`,
                interval: !auxToday && devices?.id_tipo_dispositivo === dispositivos.ccmabt ? "60" : "5",
                axis: [
                    {
                        idDeviceType: Number(device?.id_tipo_dispositivo),
                        fields: aux,
                        numseries: [`${device?.numserie}`],
                        unit: typeField,
                    }
                ]
            })
        })
        .then((res) => res.json())
        .then((data) => {
            setGraphData(cargarDatosGrafica(data))
        })
        .catch((err) => {
            console.error("ERROR. API Scada", err)
        })
        .finally(() => {
            setLoadingGraph(false)
        })
    }

    const selectorFecha = (tipo) => {
        if (tipo === "day" && (daily !== "days" || daily !== "hours")) {
            setDaily("days")
            setGroupDate(tipo)
        } else if (tipo === "month" && (daily !== "days" || daily !== "months")) {
            setDaily("months")
            setGroupDate(tipo)
        } else if (tipo === "year" && (daily !== "months" || daily !== "years")) {
            setDaily("months")
            setGroupDate(tipo)
        }
    }

    const handleTab = (index) => {
        // setLoadingGraph(true)
        setGraphData()
        setActiveTabGraph(index)
        switch (index) {
            case 0:
                setTipoField('kW')
                setTypeGraph('pact')
                break
            case 1:
                setTipoField('A')
                setTypeGraph('corriente')
                break
            case 3:
                setTipoField('V')
                setTypeGraph('tension')
                break
            case 2:
                setTipoField('kWh')
                setTypeGraph('energia')
                break
            default:
                break
        }
    }

    useEffect(() => {
        let visible = compararFecha(start, end)
        setGraphVisible(visible)
        if (!visible) {
            handleTab(2)
        } else {
            if (activeTabGraph === 2) {
                fetchEnergy()
            } else {
                setLoadingGraph(true)
                setGraphData()
                fetchDataGraph()
            }
        }
    }, [device, activeTabGraph, start])

    useEffect(() => {
        if (activeTabGraph) {
            setLoadingGraph(true)
        }
        fetchEnergy()
        setToday(esHoy(start))
    }, [start, daily, device, activeTabGraph])




    return (
        <Grid container spacing={0} className="pl-3 pr-3">
            {device &&
                <>
                    <Grid item xs={12} md={12} lg={12} xl={12} className="flex-space-between align-items-end box-shadow-0 margin-bot-10px">
                        <span className="flex-center justify-end">
                            {device?.id_tipo_dispositivo === dispositivos.ccmabtm &&
                                <DropDownDevices device={device} masterPermission={masterPermission} />
                            }
                            <h3 className="ml-2 mr-2"> {device?.nick}</h3>
                        </span>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <div className="grayDivider mb-3" />
                    </Grid>
                    {data && energyData ?
                        <>
                            {device.id_tipo_dispositivo === dispositivos.ccmabtm &&
                                <>
                                    <Grid item xs={8} md={8} lg={8} xl={8} className={`${mobile ? 'height-auto' : 'height-50'}`}>
                                        <ConsuptionPowerRealTime ccm={devices} data={data?.currentData} />
                                        <ConsuptionIntensityRealTime ccm={devices} data={data?.currentData} />
                                    </Grid>
                                    <Grid item xs={4} md={4} lg={4} xl={4}>
                                        <ConsuptionTensionRealTime ccm={devices} data={data?.currentData} mabt={true} />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} xl={12} style={{ padding: window.innerWidth < 1536 ? "3%" : "0%" }}>
                                        <ul className="nav nav-tabs list-general mb-5">
                                            <li className="nav-item" button selected={activeTabDevice === 0} onClick={() => { toggleDevice(0) }}>
                                                <span className="nav-link active list-p">Informes</span>
                                            </li>
                                        </ul>
                                        <Grid container>
                                            <Grid item xs={12} md={8} lg={8} xl={8}>
                                                <MenuInformesConsumo id_tipo_dispositivo={devices.id_tipo_dispositivo} activeTab={activeTabGraph} seleccionarTab={handleTab} graphVisible={graphVisible} />
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={4} xl={4}>
                                                <div style={{ display: "flex", justifyContent: window.innerWidth > 1536 ? "flex-end" : "center", marginTop: "1%", marginBottom: window.innerWidth > 1536 ? "0%" : "5%", marginLeft: window.innerWidth > 1300 && window.innerWidth < 1500 ? "10%" : "0%", alignItems: "flex-start" }}>
                                                    <div className="margin-right-0_5em">
                                                        <CalendarComponent setSelectDaily={setSelectDaily}
                                                            maxDate={end} setStart={setStart} setEnd={setEnd} setDaily={setDaily} />
                                                    </div>
                                                    <div className="margin-right-0_5em">
                                                        <SelectorFecha selectDaily={selectDaily}
                                                            daily={daily} setDaily={setDaily} tipo={daily} />
                                                    </div>
                                                    <CSVLink data={energyData ? cambiarCabecera(energyData, mabt, true, false, today) : []} separator={";"} className="color-white" filename={`${device?.nick ? device?.nick : 'Sin nombre ' + device?.position}.csv`}>
                                                        <HtmlTooltip title={<span>Descargar datos</span>}>
                                                            <IconDownloadFile fill={(energyData && energyData?.length > 0) && '#FF8A1F'} />
                                                        </HtmlTooltip>
                                                    </CSVLink>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} xl={12} className="flex-start justify-start margin-bot-15px">
                                            <span style={{ height: 30 }} className="d-flex align-items-center margin-right-10px cursor-pointer" onClick={() => setGraphVisibility(!graphVisibility)} >
                                                <OjoConsumo onClick={() => setGraphVisibility(!graphVisibility)} />
                                            </span>
                                            <h3>{start.format("DD-MM-YYYY")}</h3>
                                        </Grid>
                                        {graphData && !loadingGraph ?
                                            <Grid item xs={12} md={12} lg={12} xl={12} className={"powerMargins"}>
                                                {graphVisibility && <GraficaMabt datosGrafica={graphData} escalaTipo={typeField} tipoGrafica={typeGraph} />}
                                            </Grid>
                                            :
                                            <Grid item xs={12} md={12} lg={12} xl={12} className='mt-2 mb-6'>
                                                <div className="justify-content-center">
                                                    <Loader />
                                                </div>
                                            </Grid>
                                        }
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} xl={12} className="margin-top-20px margin-bot-10px">
                                        <ConsuptionTable daily={daily} data={data?.currentData} energyData={energyData} loading={loading} start={start} hoy={esHoy(start)} />
                                    </Grid>
                                </>
                            }
                            {device.id_tipo_dispositivo === dispositivos.ccmabtv &&
                                <ScadaVtn data={data} loading={loadingGraph} graphData={graphData}
                                    energyData={energyData} start={start} device={device} mabt={mabt} now={now} end={end} setStart={setStart} setEnd={setEnd}
                                    selectorFecha={selectorFecha} daily={daily} setDaily={setDaily} groupDate={groupDate} />
                            }
                        </>
                        :
                        <Grid item xs={12} md={12} lg={12} xl={12} className='mt-2 mb-6'>
                            <div className="justify-content-center">
                                <Loader />
                            </div>
                        </Grid>
                    }
                </>
            }
        </Grid>
    )
}