import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'
import { SwitchStyled } from 'components/Switch/SwitchStyled'
import { Loader } from 'components/Loader/Loader'
import { devicesAllowedTablePlant, urls } from 'utils/constant'
import { IconOk } from 'utils/icon-centrocontrol'
import { headersScada } from 'services/headers'
import { ModalConfigGlobalEms } from './Modal/ModalConfigGlobalEms'
import { TableConfigPlant } from './TableConfigPlant'
import { InyectionEms } from './InyectionEms'
import { SurplusEms } from './SurplusEms'
import { BateryEms } from './BateryEms'
import { PotMaxEms } from './PotMaxEms'
import "./styleEms.scss"

export const EmsScada = ({ devices, autoconsumo }) => {
    const [activeTabDevice, setActiveTabDevice] = useState(-1)
    const [emsEnableInitial, setEmsEnableInitial] = useState(false)
    const [emsEnable, setEmsEnable] = useState(false)
    const [configOrder, setConfigOrder] = useState(false)
    const [configOrderInitial, setConfigOrderInitial] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const [loading, setLoading] = useState(false)
    const [loadingEms, setLoadingEms] = useState(false)
    const [configEms, setConfigEms] = useState({})
    const [open, setOpen] = useState(false)
    const [meter, setMeter] = useState(false)
    const token = sessionStorage?.token

    const handleEnable = () => {
        setEmsEnable(!emsEnable)
    }

    const compareOptions = () => {
        let change = true
        if (emsEnableInitial !== emsEnable) {
            change = false
        } else {
            configOrder?.forEach((ele, index) => {
                if (ele?.ID_plant !== configOrderInitial?.[index]?.ID_plant) {
                    change = false
                }
            })
        }
        setDisabled(change)
    }

    const fetchConfig = async () => {
        setLoading(true)
        try {
            const res = await fetch(urls.scada + `getConfigPlantInstallation/${devices?.[0]?.numserie}?idCCMaster=${devices?.[0]?.numserie}`, { method: 'GET', headers: headersScada(token) })
            if (res.status === 200) {
                let json = await res.json()
                let data = json.filter(ele => devicesAllowedTablePlant?.includes(ele?.id_tipo_dispositivo))
                const copyArray1 = data.map(obj => ({ ...obj }))
                const copyArray2 = data.map(obj => ({ ...obj }))
                setConfigOrder(copyArray1)
                setConfigOrderInitial(copyArray2)
            }
            return true
        } catch (err) {
            console.error("No se ha podido traer datos de la API", err)
        } finally {
            setLoading(false)
        }
    }

    const fetchEms = async () => {
        setLoadingEms(true)
        try {
            const res = await fetch(urls.scada + `emsConfigScada/${devices?.[0]?.numserie}?idCcMaster=${devices?.[0]?.numserie}`, { method: 'GET', headers: headersScada(token) })
            let json = await res.json()
            if (res.status === 200) {
                setEmsEnableInitial(json?.enable_ems)
                setEmsEnable(json?.enable_ems)
                setConfigEms({ ...json })
                return json
            } else {
                setConfigEms({ ...json })
                return []
            }
        } catch (err) {
            console.error("ERROR. API Centro Control", err)
        } finally {
            setLoadingEms(false)
        }
    }

    useEffect(() => {
        if (configOrder?.length > 0) {
            compareOptions()
        } 
    }, [emsEnable, emsEnableInitial, configOrder])

    useEffect(() => {
        if (configOrderInitial?.length > 0) {
            let anyMeter = false
            configOrderInitial?.forEach(ele => {
                if (ele?.ID_plant === 'CONS_0001') {
                    anyMeter = true
                }
            })
            setMeter(anyMeter)
        }
    }, [configOrderInitial])

    useEffect(() => {
        fetchEms()
        fetchConfig()
    }, [])

    return (
        <Grid container className="container-tab-ems-scada">
            <Grid item xs={12} md={12} lg={12} xl={12} className='mt-3'>
                {(loading || loadingEms) && <Loader />}
            </Grid>
            {!loading && !loadingEms && configEms?.message !== undefined && 
                <Grid items xs={12} md={12} lg={12} xl={12} className="d-flex justify-content-center align-items-center flex-column p-4">
                    <h2>El máster no está conectado</h2>
                    <ButtonStyled onClick={() => fetchEms()}>Intentar Conectar</ButtonStyled>
                </Grid>
            }
            {!loading && !loadingEms && configEms?.message === undefined &&
                <>
                    <Grid item xs={12} md={12} lg={12} xl={12} className='mt-3'>
                        <SwitchStyled id="emsEnable" className="switch-small" checked={emsEnable} onClick={() => handleEnable()} />
                        <label className="ml-2 mb-0" htmlFor='emsEnable'> EMS </label>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12} className='mt-3'>
                        <TableConfigPlant autoconsumo={autoconsumo} configOrder={configOrder} setConfigOrder={setConfigOrder} />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12} className="button-apply-table d-flex justify-content-flex-end mt-3" >
                        <ButtonStyled size="small" className="" variant="" disabled={disabled} onClick={() => setOpen(true)}>Aplicar</ButtonStyled>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12} >
                        <span className='listItem-componente'>
                            <ul className="nav nav-tabs mt-3" >
                                <li className={`flex-center margin-left-1-px mr-2 list-item-icon  active-button-list-device pointer-button tab-style-mabt ${activeTabDevice === 0 ? "active-button" : null} `} selected={activeTabDevice === 0} onClick={() => { setActiveTabDevice(0) }}>
                                    {configEms?.potmax?.enable && <IconOk />} Pot. Max
                                </li>
                                {autoconsumo &&
                                    <>
                                        <li className={`flex-center ml-0 mr-2 list-item-icon  active-button-list-device pointer-button tab-style-mabt ${activeTabDevice === 1 ? "active-button" : null} `} selected={activeTabDevice === 1} onClick={() => { setActiveTabDevice(1) }}>
                                            {configEms?.self_consumption?.enable && <IconOk />} Inyección 0
                                        </li>
                                        <li className={`flex-center ml-0 mr-2 list-item-icon  active-button-list-device pointer-button tab-style-mabt ${activeTabDevice === 2 ? "active-button" : null} `} onClick={() => { setActiveTabDevice(2) }}>
                                            {configEms?.battery?.enable && <IconOk />} Baterías
                                        </li>
                                        <li className={`flex-center ml-0 mr-2 list-item-icon  active-button-list-device pointer-button tab-style-mabt ${activeTabDevice === 3 ? "active-button" : null} `} onClick={() => { setActiveTabDevice(3) }}>
                                            {configEms?.surplus?.enable && <IconOk />} Surplus
                                        </li>
                                    </>
                                }
                            </ul>
                        </span>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        {activeTabDevice === 0 &&
                            <PotMaxEms configEms={configEms} master={devices?.[0]} meter={meter} setConfigEms={setConfigEms} />
                        }
                        {activeTabDevice === 1 &&
                            <InyectionEms configEms={configEms} master={devices?.[0]} setConfigEms={setConfigEms} />
                        }
                        {activeTabDevice === 2 &&
                            <BateryEms configEms={configEms} master={devices?.[0]} setConfigEms={setConfigEms} />
                        }
                        {activeTabDevice === 3 &&
                            <SurplusEms configEms={configEms} master={devices?.[0]} meter={meter} setConfigEms={setConfigEms} />
                        }
                    </Grid>
                </>
            }
            {open &&
                <ModalConfigGlobalEms
                    emsEnable={emsEnable} master={devices?.[0]} open={open} setOpen={setOpen} configEms={configEms}
                    setConfigOrderInitial={setConfigOrderInitial} setEmsEnableInitial={setEmsEnableInitial}
                    configOrder={configOrder} configOrderInitial={configOrderInitial}
                />
            }
        </Grid>
    )
}