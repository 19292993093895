import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from 'context/GlobalContext'
import { ScadaBatery } from 'views/Scada/SelfConsumption/Batery/ScadaBatery'
import { ScadaConsumption } from 'views/Scada/SelfConsumption/Consumption/ScadaConsumption'
import { ScadaProduction } from 'views/Scada/SelfConsumption/Production/ScadaProduction'
import { ScadaGeneral } from 'views/Scada/SelfConsumption/General/ScadaGeneral'
import { ConfiguracionList } from 'views/Configuracion/ConfiguracionList'
import { ScadaRed } from 'views/Scada/SelfConsumption/Red/ScadaRed'
import { OnOff } from 'views/Scada/CcMaster/tabs/OnOff'
import { Alarms } from 'views/Alarms/Alarms'
import { headersScada } from 'services/headers'
import { urls } from 'utils/constant'
import moment from 'moment/moment'

export const ScadaSelfConsumption = (props) => {
    const token = sessionStorage?.token
    const [childresData, setChildresData] = useState()
    const [loading, setLoading] = useState(true)
    const numserieSelfConsumption = props?.master?.childrenDevices?.filter(device => device.id_tipo_dispositivo === 7)
    const startOfToday = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    const endOfToday = moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
    const [batteryNumSerie, setBatteryNumSerie] = useState()
    const [daily, setDaily] = useState("days")
    const { updateDevice } = useContext(GlobalContext)
    const bateria21 = props?.childrenDevices?.filter(dvs => dvs.id_tipo_dispositivo === 9)
    const handleButton = () => props.setSelfConsumption('general')

    const fetchChildrens = async () => {
        fetch(urls.scada + `getccm/${numserieSelfConsumption[0]?.numserie}?idCCMaster=${numserieSelfConsumption[0]?.master_serial}`,
            { method: 'GET', headers: headersScada(token) })
            .then(res => res.json())
            .then(data => {
                setChildresData(data)
            })
            .catch(err => {
                console.error("ERROR. API Scada", err)
            })
    }

    useEffect(() => {
        async function fetchData() {
            if (numserieSelfConsumption !== undefined) {
                setLoading(true)
                await fetchChildrens()
                setLoading(false)
            }
        }
        fetchData()
    }, [props?.master, updateDevice])

    useEffect(() => {
        props?.setStart(startOfToday)
        props?.setEnd(endOfToday)
        setDaily("days")

    }, [props.selfConsumption])

    return (
        <>
            {props.selfConsumption === 'production' && props.activeTabDevice === 0 &&
                <ScadaProduction
                    loose={props?.loose}
                    isMobile={props?.isMobile}
                    setRefresh={props?.setRefresh}
                    refresh={props?.refresh}
                    startt={props.startt}
                    data={childresData?.produccion}
                    handleButton={handleButton}
                    device={props?.devices}
                    start={props.start}
                    end={props.end}
                    groupData={props.groupData}
                    setStart={props.setStart}
                    now={props.now}
                    setEnd={props.setEnd}
                    setgroupData={props.setgroupData}
                    titleButton={props.titleButton}
                    setTitleButton={props.setTitleButton}
                    handleClickRight={props.handleClickRight}
                    handleClickLeft={props.handleClickLeft}
                    ends={props?.ends} daily={daily}
                    setDaily={setDaily}
                    childrenDevices={props.childrenDevices}
                    master={props?.master}
                    masterPermission={props?.masterPermission}
                />
            }
            {props.selfConsumption === 'red' && props.activeTabDevice === 0 && childresData?.red &&
                <ScadaRed
                    loose={props?.loose}
                    isMobile={props?.isMobile}
                    setRefresh={props?.setRefresh}
                    refresh={props?.refresh}
                    startt={props.startt}
                    data={childresData?.red}
                    handleButton={handleButton}
                    device={props?.devices}
                    start={props.start}
                    end={props.end}
                    groupData={props.groupData}
                    setStart={props.setStart}
                    now={props.now}
                    setEnd={props.setEnd}
                    setgroupData={props.setgroupData}
                    titleButton={props.titleButton}
                    setTitleButton={props.setTitleButton}
                    handleClickRight={props.handleClickRight}
                    ends={props?.ends}
                    handleClickLeft={props.handleClickLeft}
                    daily={daily}
                    setDaily={setDaily}
                    childrenDevices={props.childrenDevices}
                    master={props?.master}
                    masterPermission={props?.masterPermission}
                />
            }
            {props.selfConsumption === 'consumption' && props.activeTabDevice === 0 && childresData?.consumo &&
                <ScadaConsumption
                    loose={props?.loose}
                    isMobile={props?.isMobile}
                    setRefresh={props?.setRefresh}
                    refresh={props?.refresh}
                    startt={props.startt}
                    data={childresData?.consumo}
                    handleButton={handleButton}
                    device={props?.devices}
                    start={props.start}
                    end={props.end}
                    groupData={props.groupData}
                    setStart={props.setStart}
                    now={props.now}
                    setEnd={props.setEnd}
                    setgroupData={props.setgroupData}
                    titleButton={props.titleButton}
                    setTitleButton={props.setTitleButton}
                    handleClickRight={props.handleClickRight}
                    ends={props?.ends}
                    handleClickLeft={props.handleClickLeft}
                    daily={daily}
                    setDaily={setDaily}
                    childrenDevices={props.childrenDevices}
                    master={props?.master}
                    masterPermission={props?.masterPermission}
                />
            }
            {props.selfConsumption === 'batery' && props.activeTabDevice === 0 && childresData?.bateria &&
                <ScadaBatery
                    loose={props?.loose}
                    isMobile={props?.isMobile}
                    setRefresh={props?.setRefresh}
                    refresh={props?.refresh}
                    startt={props.startt}
                    data={childresData?.bateria}
                    bateria21={bateria21}
                    handleButton={handleButton}
                    device={props?.devices}
                    start={props.start}
                    end={props.end}
                    groupData={props.groupData}
                    setStart={props.setStart}
                    now={props.now}
                    setEnd={props.setEnd}
                    setgroupData={props.setgroupData}
                    titleButton={props.titleButton}
                    setTitleButton={props.setTitleButton}
                    handleClickRight={props.handleClickRight}
                    ends={props?.ends}
                    handleClickLeft={props.handleClickLeft}
                    daily={daily}
                    setDaily={setDaily}
                    childrenDevices={props.childrenDevices}
                    master={props?.master}
                    masterPermission={props?.masterPermission}
                />
            }
            {props.selfConsumption === 'general' && props.activeTabDevice === 0 &&
                <ScadaGeneral
                    bateria21={bateria21}
                    batteryNumSerie={batteryNumSerie}
                    childrenDevices={props.childrenDevices}
                    daily={daily}
                    data={childresData?.consumo}
                    device={props?.devices}
                    end={props.end}
                    ends={props?.ends}
                    groupData={props.groupData}
                    handleButton={handleButton}
                    handleClickLeft={props.handleClickLeft}
                    handleClickRight={props.handleClickRight}
                    importSelf={props?.importSelf}
                    loading={loading}
                    master={props?.master}
                    now={props.now}
                    numserieSelfConsumption={numserieSelfConsumption}
                    refresh={props?.refresh}
                    setBatteryNumSerie={setBatteryNumSerie}
                    setDaily={setDaily}
                    setEnd={props.setEnd}
                    setExportSelf={props?.setExportSelf}
                    setgroupData={props.setgroupData}
                    setImportSelf={props?.setImportSelf}
                    setRefresh={props?.setRefresh}
                    setStart={props.setStart}
                    setTitleButton={props.setTitleButton}
                    start={props.start}
                    startt={props.startt}
                    titleButton={props.titleButton}
                    isMobile={props?.isMobile}
                    masterPermission={props?.masterPermission}
                />
            }
            {props.activeTabDevice === 1 &&
                <Alarms instalacioneSeleccionadas={[props.numserieMaster]} dispositivos={[props.dispositivos]} />
            }
            {props.activeTabDevice === 2 &&
                <ConfiguracionList scadaSelf={true} instalacioneSeleccionadas={[props.numserieMaster]} dispositivo={[props.dispositivos]} scada={true} masterError={props?.masterError} autoconsumo={true} />
            }
            {props.activeTabDevice === 4 &&
                <OnOff activeTab={props?.activeTabDevice} isMobile={props?.isMobile} />
            }

        </>
    )
}