export const urls = {
    energy: process.env.REACT_APP_URL_ENERGY,
    alarms: process.env.REACT_APP_URL_ALARMS,
    onoff: process.env.REACT_APP_URL_ONOFF,
    ccontrol: process.env.REACT_APP_URL_CCONTROL,
    scada: process.env.REACT_APP_URL_SCADA,
    users: process.env.REACT_APP_URL_USERS
}

export const dispositivos = {
    ccmaster: Number(process.env.REACT_APP_CCMASTER),
    ccm1: Number(process.env.REACT_APP_CCM1),
    ccm2: Number(process.env.REACT_APP_CCM2),
    ccm3: Number(process.env.REACT_APP_CCM3),
    ccm4: Number(process.env.REACT_APP_CCM4),
    inversor: Number(process.env.REACT_APP_INVERSOR),
    analizador: Number(process.env.REACT_APP_ANALIZADOR),
    planta: Number(process.env.REACT_APP_PLANTA),
    dataLogger: Number(process.env.REACT_APP_DATALOGGER),
    bateria: Number(process.env.REACT_APP_BATERIA),
    ups: Number(process.env.REACT_APP_UPS),
    bombilla: Number(process.env.REACT_APP_BOMBILLA),
    enchufe: Number(process.env.REACT_APP_ENCHUFE),
    sensor: Number(process.env.REACT_APP_SENSOR),
    ventilacion: Number(process.env.REACT_APP_VENTILACION),
    supervisor: Number(process.env.REACT_APP_SUPERVISOR),
    maquinaTermica: Number(process.env.REACT_APP_MAQUINA_TERMICA),
    termostato: Number(process.env.REACT_APP_TERMOSTATO),
    cargadorVehiculos: Number(process.env.REACT_APP_CARGADOR_VEHICULOS),
    fototermia: Number(process.env.REACT_APP_FOTOTERMIA),
    ccmabt: Number(process.env.REACT_APP_CCMABT),
    ccmabtm: Number(process.env.REACT_APP_CCMABT_M),
    persiana: Number(process.env.REACT_APP_PERSIANA),
    ccmabtv: Number(process.env.REACT_APP_CCMABT_V)
}

export const instalaciones = {
    consumption: Number(process.env.REACT_APP_CONSUMPTION),
    selfConsumption: Number(process.env.REACT_APP_SELF_CONSUMPTION),
    photovoltaic: Number(process.env.REACT_APP_PHOTOVOLTAIC),
    mabt: Number(process.env.REACT_APP_MABT),
    lighting: Number(process.env.REACT_APP_LIGHTING)
}

export const alarmas = {
    conexionDataLogger: Number(process.env.REACT_APP_CONEXION_DATALOGGER),
    comunicacion: Number(process.env.REACT_APP_COMUNICACION),
    tramo: Number(process.env.REACT_APP_TRAMO),
    proteccion: Number(process.env.REACT_APP_PROTECCION),
    manual: Number(process.env.REACT_APP_MANUAL)
}

export const alarmasConfig = {
    conexionDataLogger: Number(process.env.REACT_APP_CONEXION_DATALOGGER),
    comunicacion: Number(process.env.REACT_APP_COMUNICACION),
    tramo: Number(process.env.REACT_APP_TRAMO)
}

export const devicesCentroControlConsumption = [
    Number(process.env.REACT_APP_CCM1),
    Number(process.env.REACT_APP_CCM2),
    Number(process.env.REACT_APP_CCM3),
    Number(process.env.REACT_APP_CCM4),
    Number(process.env.REACT_APP_INVERSOR),
    Number(process.env.REACT_APP_ANALIZADOR)
]

export const devicesAllowedTablePlant = [dispositivos.ccm1, dispositivos.ccm2, dispositivos.ccm3, dispositivos.ccm4, dispositivos.analizador, dispositivos.inversor, dispositivos.enchufe]

export const devicesAllowedPF = [dispositivos.ccm2, dispositivos.ccm4, dispositivos.analizador]

export const devicesAllowedProd = [dispositivos.ccm2, dispositivos.ccm4, dispositivos.analizador, dispositivos.inversor]

export const devicesAllowedConsumption = [dispositivos.ccm1, dispositivos.ccm2, dispositivos.ccm3, dispositivos.ccm4, dispositivos.analizador, dispositivos.enchufe]

export const devicesMinervaConsumption = [
    Number(process.env.REACT_APP_CCMASTER),
    Number(process.env.REACT_APP_CCM1),
    Number(process.env.REACT_APP_CCM2),
    Number(process.env.REACT_APP_CCM3),
    Number(process.env.REACT_APP_CCM4),
    Number(process.env.REACT_APP_INVERSOR),
    Number(process.env.REACT_APP_ANALIZADOR),
    Number(process.env.REACT_APP_CCMABT),
    Number(process.env.REACT_APP_ENCHUFE)
]

export const devicesMinervaSelfConsumption = [
    Number(process.env.REACT_APP_CCMASTER),
    Number(process.env.REACT_APP_CCM1),
    Number(process.env.REACT_APP_CCM2),
    Number(process.env.REACT_APP_CCM3),
    Number(process.env.REACT_APP_CCM4),
    Number(process.env.REACT_APP_INVERSOR),
    Number(process.env.REACT_APP_ANALIZADOR),
    Number(process.env.REACT_APP_PLANTA),
    Number(process.env.REACT_APP_BATERIA),
    Number(process.env.REACT_APP_CCMABT)
]

export const apiMap = process.env.REACT_APP_GOOGLE_MAP

export const VERSION = '1.9.1'

export const firmware = ["0.3.17", "1.0.1", "1.0.2", "1.0.3", "1.0.4", "1.0.5", "1.0.6", "1.0.7", "1.1.0", "1.1.4", "1.1.5", "1.1.6", "1.1.7", "1.1.8", "1.1.9"]

export const valueMax = 1844674407370955

export const modeEMS = [
    { value: 0, mode: "Normal" },
    // { value: 1, mode: "Stop" },
    { value: 5, mode: "Surplus" },
    { value: 6, mode: "Pot. Max" }
]

export const vertidos = [
    ['rgb(58, 135, 29)'],
    ['rgb(130, 154, 30)'],
    ['rgb(135, 179, 68)'],
    ['rgb(153, 205, 133)'],
    ['rgb(196, 228, 119)'],
    ['rgb(55, 89, 29)'],
    ['rgb(58, 135, 29)'],
    ['rgb(130, 154, 30)'],
    ['rgb(135, 179, 68)'],
    ['rgb(153, 205, 133)'],
    ['rgb(196, 228, 119)'],
]

export const consumidos = [
    ['rgb(20, 20, 255)'],
    ['rgb(50, 20, 255)'],
    ['rgb(60, 60, 255)'],
    ['rgb(60, 80, 255)'],
    ['rgb(60, 100, 255)'],
    ['rgb(60, 120, 255)'],
    ['rgb(60, 160, 255)'],
    ['rgb(60, 190, 255)'],
    ['rgb(60, 210, 255)'],
    ['rgb(60, 230, 255)'],
    ['rgb(60, 250, 255)'],
    ['rgb(70, 20, 255)'],
    ['rgb(90, 50, 255)'],
    ['rgb(90, 80, 255)'],
    ['rgb(90, 110, 255)'],
    ['rgb(90, 130, 255)'],
    ['rgb(90, 160, 255)'],
    ['rgb(90, 190, 255)']
]

export const optionsMode = [
    { label: 'Encender', value: 100 },
    { label: 'Apagar', value: 103 }
]

export const optionsRelay = [
    { label: 'Todos', value: [1, 2] },
    { label: 'Relay 1', value: [1] },
    { label: 'Relay 2', value: [2] }
]
