import React, { useContext, useState } from "react"
import { useLocation } from "react-router-dom"
import { Grid } from "@mui/material"
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { GlobalContext } from "context/GlobalContext"
import LayOutBoard from "views/LayOut/LayOutBoard"
import { EmsScada } from "views/EMS/EmsScada"
import { IconWarning } from "utils/icon-centrocontrol"
import { dispositivos } from "utils/constant"
import { PersonalizationConfig } from "./Personalization/PersonalizationConfig"
import { ConfiguracionPlantaMabt } from "./Mabt/ConfiguracionPlantaMabt"
import { InstallationConfig } from "./Installation/InstallationConfig"
import { ConfiguracionAlarms } from "./Alarms/ConfiguracionAlarms"
import { instalaciones } from "utils/constant"
import { EmsCC } from "views/EMS/EmsCC"
import "./Configuracion.scss"

export const ConfiguracionList = ({ consumption, instalacioneSeleccionadas, dispositivo, scada, masterError, scadaSelf, activeTabCuadro, autoconsumo }) => {
  const location = useLocation()
  const centroControl = location.pathname.split('/')[1] === "admin"
  const [activeTab, setActiveTab] = useState(activeTabCuadro ? 4 : 0)
  const errors = masterError.filter(master => master.error !== 0)
  const valid = dispositivo?.[0]?.id_tipo_dispositivo === 0 ? true : false
  const { actualProject } = useContext(GlobalContext)

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const emsEnable = [instalaciones.consumption, instalaciones.selfConsumption]


  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12} xl={12} className="menu-configuracion">
        <div selected={activeTab === 0} onClick={() => { toggle(0) }}>
          <span className={activeTab === 0 ? "list-item-icon active-button" : "list-item-icon"} >
            Alarmas
          </span>
        </div>

        {!scada &&
          <div selected={activeTab === 1} onClick={() => { toggle(1) }}>
            <span className={activeTab === 1 ? "list-item-icon active-button" : "list-item-icon"} >
              Personalización
            </span>
          </div>
        }
        {emsEnable?.includes(actualProject?.category) &&
          <div selected={activeTab === 5} onClick={() => { toggle(5) }}>
            <span className={activeTab === 5 ? "list-item-icon active-button" : "list-item-icon"} >
              Ems
            </span>
          </div>
        }

        {(scada && dispositivo[0].id_tipo_dispositivo === dispositivos.ccmabt && actualProject?.permissions !== "user") &&
          <div selected={activeTab === 2} onClick={() => { toggle(2) }}>
            <span className={activeTab === 2 ? "list-item-icon active-button" : "list-item-icon"} >
              Instalación
              {masterError && masterError?.length > 0 &&
                <div className='separator-left'>
                  <HtmlTooltip title={<span>Revise su configuración.</span>}>
                    <IconWarning width={20} height={20} />
                  </HtmlTooltip>
                </div>
              }
            </span>
          </div>
        }
        {((!scada && actualProject?.permissions !== "user") || (dispositivo[0].inst_type !== 3 && actualProject?.permissions !== "user")) &&
          <div selected={activeTab === 3} onClick={() => { toggle(3) }}>
            <span className={activeTab === 3 ? "list-item-icon active-button" : "list-item-icon"}>
              Instalación
              {errors && errors?.length > 0 &&
                <div className='separator-left'>
                  <HtmlTooltip title={<span>Revise su configuración.</span>}>
                    <IconWarning width={20} height={20} />
                  </HtmlTooltip>
                </div>
              }
            </span>
          </div>
        }
        {consumption &&
          <div selected={activeTab === 4} onClick={() => { toggle(4) }}>
            <span className={activeTab === 4 ? "list-item-icon active-button" : "list-item-icon"}>
              Configurar cuadro
            </span>
          </div>
        }
        {(consumption || scadaSelf) && valid &&
          <div selected={activeTab === 5} onClick={() => { toggle(5) }}>
            <span className={activeTab === 5 ? "list-item-icon active-button" : "list-item-icon"}>
              Ems
            </span>
          </div>
        }
      </Grid>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <div className={`tab-item-wrapper ${activeTab === 0 ? 'active' : ''}`} index={0}>
          {activeTab === 0 && <ConfiguracionAlarms instalacioneSeleccionadas={instalacioneSeleccionadas} dispositivos={dispositivo} />}
        </div>
        <div className={`tab-item-wrapper ${activeTab === 1 ? 'active' : ''}`} index={1}>
          {activeTab === 1 && <PersonalizationConfig idProject={actualProject?.id_project} />}
        </div>
        <div className={`tab-item-wrapper ${activeTab === 2 ? 'active' : ''}`} index={2}>
          {activeTab === 2 && <ConfiguracionPlantaMabt instalacioneSeleccionadas={instalacioneSeleccionadas} dispositivo={dispositivo} />}
        </div>
        <div className={`tab-item-wrapper ${activeTab === 3 ? 'active' : ''}`} index={3}>
          {activeTab === 3 && <InstallationConfig scadaSelf={scadaSelf} activeTab={activeTab} instalacioneSeleccionadas={instalacioneSeleccionadas} dispositivos={dispositivo} masterError={masterError} />}
        </div>
        {consumption &&
          <div className={`tab-item-wrapper ${activeTab === 4 ? 'active' : ''}`} index={4}>
            {activeTab === 4 && <LayOutBoard dispositivo={dispositivo} />}
          </div>
        }
        {(consumption || scadaSelf) &&
          <div className={`tab-item-wrapper2 ${activeTab === 5 ? 'active' : ''}`} index={5}>
            {activeTab === 5 && <EmsScada devices={dispositivo} autoconsumo={autoconsumo} />}
          </div>
        }
        {(actualProject.category === instalaciones.consumption || actualProject.category === instalaciones.selfConsumption) && centroControl &&
          <div className={`tab-item-wrapper2 ${activeTab === 5 ? 'active' : ''}`} index={5}>
            {activeTab === 5 && <EmsCC dispositivo={dispositivo} inst_type={actualProject.category} instSelect={instalacioneSeleccionadas} />}
          </div>
        }
      </Grid>
    </Grid>
  )
}