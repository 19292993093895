import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortAmountUp, faSortAmountDown } from '@fortawesome/free-solid-svg-icons'
import { TableCell, TableRow } from '@mui/material'

export const StyledTableRow = ({ key, style, children, className, scope, onMouseOver, onMouseOut, ref }) => {

    return (
        <TableRow key={key} align="center" style={style} ref={ref} className={className + ' table-row'} scope={scope} onMouseOut={onMouseOut} onMouseOver={onMouseOver}>
            {children}
        </TableRow>
    )
}

export const StyledTableCell = ({ style, children, className, scope, ref }) => {

    return (
        <TableCell
            align="center"
            ref={ref}
            style={style}
            className={className}
            scope={scope}
            sx={{
                color: 'rgb(87, 87, 87)',
                padding: '5px 15px',
                fontSize: '14px',
                fontStyle: 'normal',
                fontFamily: 'Montserrat',
                fontWeight: '400',
                lineHeight: '17px',
                borderBottom: '0px'
            }}
        >
            {children}
        </TableCell>
    )
}

export const StyledTableCellHead = ({ style, children, className, scope, ref }) => {

    return (
        <TableCell
            align="center"
            style={style}
            ref={ref}
            className={className}
            scope={scope}
            sx={{
                color: 'rgb(38, 38, 38)',
                padding: '5px 15px',
                fontSize: '13px',
                fontStyle: 'normal',
                fontFamily: 'Montserrat',
                fontWeight: '600',
                lineHeight: '16px'
            }}
        >
            {children}
        </TableCell>
    )
}

export const StyledTableCellProject = ({ style, children, className, scope }) => {

    return (
        <TableCell
            align="center"
            style={style}
            className={className}
            scope={scope}
            sx={{
                color: "#262626",
            }}
        >
            {children}
        </TableCell>
    )
}

export const StyledTableCellOrdenado = ({ style, children, className, scope, funcion, celda, sortConfig }) => {

    return (
        <TableCell
            align="center"
            style={style}
            className={className}
            scope={scope}
            sx={{
                fontFamily: 'Montserrat',
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "13px",
                lineHeight: "16px",
                color: "#262626",
                padding: "5px 15px"
            }}>
            <div onClick={funcion} className="d-flex justify-content-center align-items-center" style={{ cursor: "pointer" }}>
                {children}
                {sortConfig.key === celda && sortConfig.direction === "asc" &&
                    <FontAwesomeIcon className="ml-1" icon={faSortAmountDown} size="sm" color="gray" />
                }
                {sortConfig.key === celda && sortConfig.direction === "desc" &&
                    <FontAwesomeIcon className="ml-1" icon={faSortAmountUp} size="sm" color="gray" />
                }
            </div>
        </TableCell>
    )
}