
import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff } from '@fortawesome/free-solid-svg-icons'
import { MapContainer, TileLayer, Marker, Popup, LayersControl, useMapEvents } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster'
import { useDispatch } from 'react-redux'
import L from 'leaflet'
import IconMapIluminariaALLOnRelay2 from 'assets/img/IconMapIluminariaALLOnRelay2.svg' 
import IconMapIluminariaALLOnRelay1 from 'assets/img/IconMapIluminariaALLOnRelay1.svg' 
import IconMapIluminariaALLOff from 'assets/img/IconMapIluminariaALLOff.svg' 
import IconMapIluminariaALLOn from 'assets/img/IconMapIluminariaALLOn.svg' 
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'
import { createToast } from 'reduxSlice/toastSlice'
import { updateMap } from 'reduxSlice/mapSlice'
import { headersScada } from 'services/headers'
import { urls, optionsRelay, optionsMode } from 'utils/constant'
import { stateGlobal } from 'utils/Utils'
import 'leaflet/dist/leaflet.css'
import './styleMap.scss'

delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png'
})
const icons = {
    on: new L.Icon({
        iconUrl: IconMapIluminariaALLOn,
        iconSize: [40, 60],
        iconAnchor: [20, 45],
        popupAnchor: [1, -25]
    }),
    off: new L.Icon({
        iconUrl: IconMapIluminariaALLOff,
        iconSize: [40, 60],
        iconAnchor: [20, 45],
        popupAnchor: [1, -25]
    }),
    relay1: new L.Icon({
        iconUrl: IconMapIluminariaALLOnRelay1,
        iconSize: [40, 60],
        iconAnchor: [20, 45],
        popupAnchor: [1, -25]
    }),
    relay2: new L.Icon({
        iconUrl: IconMapIluminariaALLOnRelay2,
        iconSize: [40, 60],
        iconAnchor: [20, 45],
        popupAnchor: [1, -25]
    }),
    notSelected: new L.Icon({
        iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
        iconSize: [20, 32],
        iconAnchor: [10, 32],
        popupAnchor: [1, -25]
    })
}

const bounds = [
    [-90, -180],
    [90, 180]
]

export const MapShow = ({places, token}) => {
    const [firstTime, setFirstTime] = useState(true)
    const [visibleMarkers, setVisibleMarkers] = useState([])
    const [placesView, setPlacesView] = useState([])
    const [mode, setMode] = useState('')
    const [relay, setRelay] = useState('')
    const dispatch = useDispatch()
    const centro = places && places.length > 0 ? calcularPuntoIntermedio(places) : { lat: 40.41, lng: -3.70 }

    const VisibleMarkers = ({ markers }) => {
        const map = useMapEvents({
          moveend: () => {
            const bounds = map.getBounds()
            const visibles = markers.filter(marker =>
              bounds.contains(L.latLng([marker?.lat, marker?.lng]))
            )
            setVisibleMarkers(visibles)
            setFirstTime(false)
            dispatch(updateMap(visibles))
          }
        })
        return null
    }

    const fetchRelay = async (body) => {
        try {
            const res = await fetch(urls.scada + `outputsDigitals`, { method: 'POST', headers: headersScada(token), body: JSON.stringify(body)  })
            if (res.status === 200) {
                const json = await res.json()
                let updatedPlacesView = [...places]
                json.forEach(ele => {
                    const placeIndex = updatedPlacesView?.findIndex(place => place?.ns === ele?.numserie)
                    if (placeIndex !== -1) {
                        updatedPlacesView[placeIndex].int1_mode = ele?.int1_mode
                        updatedPlacesView[placeIndex].int2_mode = ele?.int2_mode
                    }
                })
                dispatch(updateMap(updatedPlacesView))
                setPlacesView(updatedPlacesView)
            }
        } catch (err) { console.error("ERROR. API Scada", err) }
    }

    const fetchEncendidoApagado = async (marker, output) => {
        let numseries = []
        let out = mode
        let rele = marker ? [output] : relay
        if (marker) {
            out = output === 1 ? outputMode(marker?.int1_mode) : outputMode(marker?.int2_mode)
            numseries = [marker?.ns]
        } else if (firstTime) {
            places?.forEach(ele => {
                if(ele?.selected) {
                    numseries.push(ele?.ns)
                }
            })
        } else {
            visibleMarkers?.forEach(ele => {
                if(ele?.selected) {
                    numseries.push(ele?.ns)
                }
            })
        }
        let body = {
            numserie: numseries,
            ID_entry: rele,
            int_mode: out,
            state: 1
        }
        if (numseries?.length > 0) {
            try {
                const res = await fetch(urls.scada + `calendarRelay`, { method: 'PATCH', headers: headersScada(token), body: JSON.stringify(body) })
                if (res.status === 200) {
                    let updatedPlacesView = [...placesView]
                    updatedPlacesView?.forEach(ele => {
                        const placeIndex = numseries?.findIndex(place => place === ele?.ns)
                        if (placeIndex !== -1) {
                            let newPlace = {...updatedPlacesView[placeIndex]}
                            if(rele?.length === 2) {
                                newPlace.int1_mode = out
                                newPlace.int2_mode = out
                                updatedPlacesView[placeIndex] = newPlace
                            } else if(rele?.length === 1 && rele?.[0] === 1) {
                                newPlace.int1_mode = out
                                updatedPlacesView[placeIndex] = newPlace
                            } else {
                                newPlace.int2_mode = out
                                updatedPlacesView[placeIndex] = newPlace
                            }
                        }
                    })
                    setPlacesView(updatedPlacesView)
                    dispatch(createToast({ status: res.status, message: "Cambios realizados correctamente", reload: false }))
                } else {
                    dispatch(createToast({ status: res.status, message: "Error al realizar los cambios", reload: false }))
                }
            } catch (err) { console.error("ERROR. API Scada", err) }
        } else {
            dispatch(createToast({ status: 400, message: "Error no hay dispositivos seleccionados", reload: false }))
        }
    }

    const checkedInternal = (mode) => {
        if (mode === 103 || mode === 101 || mode === 0) {
            return false
        } else {
            return true
        }
    }

    const outputMode = (mode) => {
        if (mode === 103 || mode === 101 || mode === 0) {
            return 100
        } else {
            return 103
        }
    }

    useEffect(() =>  {
        if (places?.length > 0) {
            // let center = calcularPuntoIntermedio(places)
            let numseries = []
            places?.forEach(ele => {
                numseries.push(ele?.ns)
            })
            fetchRelay({numseries: numseries})
        }
    }, [places])

    function calcularPuntoIntermedio(coordenadas) {
        const toRadians = (degrees) => degrees * Math.PI / 180
        const toDegrees = (radians) => radians * 180 / Math.PI
        let x = 0, y = 0, z = 0
        
        coordenadas.forEach(coordenada => {
            let lat = toRadians(coordenada.lat)
            let lng = toRadians(coordenada.lng)
            x += Math.cos(lat) * Math.cos(lng)
            y += Math.cos(lat) * Math.sin(lng)
            z += Math.sin(lat)
        })
        
        const total = coordenadas.length
        x = x / total
        y = y / total
        z = z / total
        const lngPromedio = Math.atan2(y, x)
        const hyp = Math.sqrt(x * x + y * y)
        const latPromedio = Math.atan2(z, hyp)
        
        return {
            lat: toDegrees(latPromedio),
            lng: toDegrees(lngPromedio)
        }
    }
    
    return (
        <div className='index-0'>
            {places && 
                <>
                    <Grid className='mt-1 mb-1' container spacing={2} columns={18}>
                        <Grid item>
                            <FormControl sx={{ minWidth: 180 }} size="small">
                                <InputLabel id="select-modo" style={{ color: "#8c8c8c" }}>Modo</InputLabel>
                                <Select
                                    className='tamano-select style-textfield-text'
                                    labelId="select-modo"
                                    id="select-modo"
                                    value={mode}
                                    label="Modo"
                                    onChange={(e) => { setMode(e.target.value) }}
                                >
                                    {optionsMode.map((option, index) => {
                                        return (
                                            <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl sx={{ minWidth: 180 }} size="small">
                                <InputLabel id="select-relay" style={{ color: "#8c8c8c" }}>Salida</InputLabel>
                                <Select
                                    className='tamano-select style-textfield-text'
                                    labelId="select-relay"
                                    id="select-relay"
                                    value={relay}
                                    label="Tipo Relay"
                                    onChange={(e) => { setRelay(e.target.value) }}
                                >
                                    {optionsRelay.map((option, index) => {
                                        return (
                                            <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <ButtonStyled disabled={mode === '' || relay === '' || (visibleMarkers.length === 0 && !firstTime)} onClick={() => fetchEncendidoApagado()}>
                                Enviar
                            </ButtonStyled>
                        </Grid>
                    </Grid>
                    <MapContainer
                        center={[centro.lat, centro.lng]}
                        zoom={6} 
                        style={{ height: '70vh', width: '100%' }} 
                        maxZoom={18}
                        minZoom={2}
                        maxBounds={bounds}
                        maxBoundsViscosity={1.0}
                    >
                        <LayersControl>
                            <LayersControl.BaseLayer name="Mapa">
                                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                            </LayersControl.BaseLayer>
                            <LayersControl.BaseLayer checked name="Satelite">
                                <TileLayer url="https://www.google.cn/maps/vt?lyrs=y@189&gl=cn&x={x}&y={y}&z={z}"/>
                            </LayersControl.BaseLayer>
                        </LayersControl>
                        <VisibleMarkers markers={placesView}/>
                        <MarkerClusterGroup>
                            {placesView?.map((marker, idx) => (
                                <Marker key={idx} position={[marker?.lat, marker?.lng]} icon={marker?.selected ? icons?.[stateGlobal(marker?.int1_mode, marker?.int2_mode)] : icons?.[stateGlobal(300, 300)]}>
                                    <Popup>
                                        {marker.title} <br /> Latitud: {marker?.lat} <br /> Longitud: {marker?.lng}
                                        <div className='flex-space-between pt-2'>
                                            <span className="d-flex align-items-center justify-content-start flex-column" onClick={() => { fetchEncendidoApagado(marker, 1) }}>
                                                <small >{"Salida 1"}</small>
                                                <span className={`${checkedInternal(Number(marker?.int1_mode)) ? "button-on-off-active" : "button-on-off-inactive"} button-on-off button-on-off-cc`}>
                                                    <FontAwesomeIcon icon={faPowerOff} size="x" />
                                                </span>
                                            </span>
                                            <span className="d-flex align-items-center justify-content-start flex-column" onClick={() => { fetchEncendidoApagado(marker, 2) }}>
                                                <small>{"Salida 2"}</small>
                                                <span className={`${checkedInternal(Number(marker?.int2_mode)) ? "button-on-off-active" : "button-on-off-inactive"} button-on-off button-on-off-cc`}>
                                                    <FontAwesomeIcon icon={faPowerOff} size="x" />  
                                                </span>
                                            </span>
                                        </div>
                                    </Popup>
                                </Marker>
                            ))}
                        </MarkerClusterGroup>
                    </MapContainer>
                </>
            }
        </div>
    )
}

export default MapShow