import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Loader } from "components/Loader/Loader"
import Map from "components/Mapa/MapShow"
import { headersCentroControl } from "services/headers"
import getPlaces from "./utils/getPlaces"
import { OpenToScada } from "utils/Utils"
import { urls } from "utils/constant"
import { useSelector } from "react-redux"
import MapShow from "components/Map/MapShow"
import "./StyleMap.scss"

const MapCentroControl = ({ idMaster, active, token, actualProject }) => {
  const location = useLocation()
  const idP = location?.pathname.split('/')[3]
  const [places, setPlaces] = useState([])
  const [loading, setLoading] = useState(false)
  const [firstLoading, setFirstLoading] = useState(true)
  const tree = useSelector((state) => state.treeDevices.tree)
  const errors = useSelector((state) => state.infoInstal)
  const linkScada = (numSerie) => {
    const url = OpenToScada(actualProject.category, idP, numSerie)
    window.open(url)
  }

  useEffect(() => {
    const getData = async () => {
      let url = `getMap/${idP}`
      try {
        const res = await fetch(urls.ccontrol + url, {
          method: "GET",
          headers: headersCentroControl(token)
        })
        const mapData = await res.json()
        const installSelect = Object?.entries(tree)?.filter(([_, valor]) => valor === true)?.map(([clave, _]) => clave.substring(clave?.lastIndexOf('-') + 1))
        const clavesModificadas = installSelect?.map(clave => clave?.substring(clave?.lastIndexOf('-') + 1))
        setPlaces(getPlaces(mapData.filter(data => errors?.[data?.numserie]?.error === 0), clavesModificadas))
      } catch (err) {
        console.error(err)
        setPlaces([])
      } finally {
        setLoading(false)
        setFirstLoading(false)
      }
    }
    if (active) {
      setLoading(true)
      getData()
    }
  }, [active, tree])
  
  return (
    <>
      {loading && (actualProject?.category !== 4 || firstLoading) && <Loader/> }
      {active && actualProject?.category === 4 && !firstLoading && <MapShow places={places} token={token}/>}
      {places && !loading && 
        <Map
          idMaster={idMaster}
          places={places?.filter(place => place?.selected === true)}
          zoom={5}
          className={`${
            active ? "" : "display-none"
          } ${
            actualProject?.category === 4 ? "display-none" : ""
          } mapaConfiguracionFullHeight`}
          onClickMarker={(numSerie) => linkScada(numSerie)}
        />
      }
     </>
  )
}
export default MapCentroControl
